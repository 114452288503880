import * as bootstrap from 'bootstrap';

import { conformsTo } from "lodash";

// add costum js here

/**
 * Tiny slider always on the bottom of code
 * that will effect when you towards another page
 */

// tiny slider
import { tns } from "tiny-slider/src/tiny-slider"
window.tns = tns;

// tiny slider
var slider = tns({
    container: '.slider-container',
    items: 5,
    slideBy: 'page',
    mouseDrag: true,
    fixedWidth: 400,
    speed: 400,
    controls: false,
    nav: false
})